// append urls here, DO NOT remove this line

export const URLS = {
  careers: "https://taager.bamboohr.com/careers",
  helpCenter: "/help-center",
  home: "/",
  designSystem: "/design-system",
  onboardingQuestionnaire: "/questionnaire/onboarding",
  auth: {
    login: "/auth/login",
    forgetPassword: "/auth/forgot-password",
    resetPassword: "/auth/reset-password/:id/:token",
    oldResetPassword: "/reset-password/:id/:token",
    register: "/auth/signup",
    loginWithEmail: "/auth/login-with-email",
  },
  profile: "/profile",
  contactUs: "/contact-us",
  termsConditions: "/terms-conditions",
  privacyPolicy: "/privacy-policy",
  pages: {
    learningCenter: "/learning-center",
    wallet: "/wallet",
    orders: "/orders",
    analytics: "/insights",
    shippingInfo: "/info",
    complaintsAndSuggestions: "/complain-suggest",
    incentiveProgram: "/incentive-program",
    loyaltyProgram: "/loyalty",
    preOrder: "/pre-order",
    preOrderRequests: "/pre-order-requests",
  },
  products: {
    list: "/products",
  },
  cart: "/cart",
  uxProgram: "https://uxprogram.taager.com",
};

export const completedUrlsPages = [
  URLS.home,
  URLS.auth.login,
  URLS.auth.forgetPassword,
  URLS.auth.resetPassword,
  URLS.auth.register,
  URLS.termsConditions,
];
