import { UserLoginActivityEvents } from "./login.activities";
import { UserSignUpActivityEvents } from "./sign-up.activities";

export * from "./login.activities";
export * from "./sign-up.activities";

export const UserActivityEvents = {
  ...UserSignUpActivityEvents,
  ...UserLoginActivityEvents,
} as const;
