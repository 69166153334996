import {
  BaseRepository,
  CACHE_FOR_ONE_HOUR_IN_SECONDS,
} from "@features/shared/data";
import { type GenericObject } from "@taager/reinforcements";
import { type FeaturesListRepositoryContract } from "../../domain";

export class UserFeaturesRepository
  extends BaseRepository
  implements FeaturesListRepositoryContract
{
  public async list(): Promise<GenericObject> {
    const response = await this.http.get<any>(
      "/userFeatures/getAllUserFeatures",
      {
        cache: {
          ttl: CACHE_FOR_ONE_HOUR_IN_SECONDS,
        },
      },
    );

    const features: string[] = response.data.data.map(
      (feature: any) => feature.feature,
    );

    const featuresList: GenericObject = {};

    for (const feature of features) {
      featuresList[feature] = true;
    }

    return featuresList;
  }
}
