import {
  gTagEvent,
  trackEvent,
  trackEventError,
} from "@features/activities/data";
import { getUserCountryLocation } from "@features/country/data";
import { getUserPlatform } from "@features/shared/data";
import {
  CompleteUserProfileData,
  DeprecatedCompleteUserProfileData,
  GetPhoneOTPData,
  OTPData,
  VerifyEmailData,
  VerifyPhoneNumberData,
} from "@features/user/domain";
import user from "user";

/**
 * Please list all account activity events here and DO NOT Hardcode any string in the code.
 */
export enum UserSignUpActivityEvents {
  // Start of Register Events
  // Google Tag Manager Events
  SIGN_UP_GOOGLE_TAG = "sign_up",
  // Verification Events
  SIGN_UP_OTP_PAGE_LOAD = "sign-up_otp_page_load",

  // Google Sign Up Events
  // Add V3 User Property
  SIGN_UP_BY_GOOGLE_SUCCESS = "google_continue_sign_up",

  // Email Sign Up Events
  // Add V3 User Property
  SIGN_UP_BY_EMAIL_SUCCESS = "continue_sign_up",

  // Email Verification Events
  SIGN_UP_EMAIL_VERIFICATION_REQUESTED = "email_otp_requested",
  SIGN_UP_EMAIL_VERIFICATION_REQUEST_ERROR = "email_otp_request_error",
  SIGN_UP_EMAIL_VERIFICATION_SUCCESS = "email_otp_verified_successfully",
  SIGN_UP_EMAIL_VERIFICATION_FAILED = "signup_email_otp_submit_error",

  // Phone Number Verification Events
  SIGN_UP_OTP_EDIT_PHONE = "otp_edit_phone",
  SIGN_UP_PHONE_VERIFICATION_REQUESTED = "sms_otp_requested",
  SIGN_UP_PHONE_VERIFICATION_SUCCESS = "sms_otp_verified_successfully",
  SIGN_UP_PHONE_VERIFICATION_REQUEST_ERROR = "sms_otp_request_error",
  SIGN_UP_PHONE_VERIFICATION_ERROR = "signup_otp_submit_error",

  // Add V3 User Property
  SIGN_UP_VERIFICATION_SUCCESS = "user_verification_complete",
  // End of Verification Events

  // Complete Profile Events
  // Add V3 User Property signupV3
  SIGN_UP_COMPLETE_PROFILE_SUCCESSFULLY = "sign_up_complete_profile_submitted",
  SIGN_UP_COMPLETE_PROFILE_FAILED = "complete_profile_error",
  // End of Complete Profile Events
  // End of Register Events
}

type PhoneNumberEditPayload = {
  phoneNumber: string;
  callingCode: string;
};

export function trackSignUpPhoneNumberEdit(payload: PhoneNumberEditPayload) {
  trackEvent(UserSignUpActivityEvents.SIGN_UP_OTP_EDIT_PHONE, {
    Status: "edit phoneNumber OTP",
    phone: payload.phoneNumber,
    callingCode: payload.callingCode,
    scope: "signup",
  });
}

export function trackSignUpVerificationSuccess(data?: { signupV3?: boolean }) {
  trackEvent(UserSignUpActivityEvents.SIGN_UP_VERIFICATION_SUCCESS, {
    scope: "signup",
    ...data,
  });
}

// Email Verification Events
export function trackEmailVerificationRequested(payload: OTPData) {
  trackEvent(UserSignUpActivityEvents.SIGN_UP_EMAIL_VERIFICATION_REQUESTED, {
    response: payload,
  });
}
export function trackEmailVerificationRequestError(error: any) {
  trackEventError(
    UserSignUpActivityEvents.SIGN_UP_EMAIL_VERIFICATION_REQUEST_ERROR,
    error,
  );
}

export function trackEmailVerificationSuccess(data: VerifyEmailData) {
  trackEvent(UserSignUpActivityEvents.SIGN_UP_EMAIL_VERIFICATION_SUCCESS, data);
}

export function trackEmailVerificationFailed(
  data: VerifyEmailData,
  error: any,
) {
  trackEvent(UserSignUpActivityEvents.SIGN_UP_EMAIL_VERIFICATION_FAILED, {
    "error-message": error.message,
    "error-object": error,
    "request-payload": data,
  });
}

// Phone Number Verification Events
export function trackPhoneNumberVerificationRequested(
  phoneData: GetPhoneOTPData,
  payload: OTPData,
) {
  trackEvent(UserSignUpActivityEvents.SIGN_UP_PHONE_VERIFICATION_REQUESTED, {
    response: payload,
    phoneData,
  });
}

export function trackPhoneNumberVerificationRequestError(
  phoneData: GetPhoneOTPData,
  error: any,
) {
  trackEvent(
    UserSignUpActivityEvents.SIGN_UP_PHONE_VERIFICATION_REQUEST_ERROR,
    {
      error,
      phoneData,
    },
  );
}

export function trackPhoneNumberVerificationFailed(
  phoneData: VerifyPhoneNumberData,
  error: any,
) {
  trackEvent(UserSignUpActivityEvents.SIGN_UP_PHONE_VERIFICATION_ERROR, {
    "error-message": error.message,
    "error-object": error,
    "request-payload": {
      checkCode: phoneData.checkCode,
      otpPasscode: phoneData.otpPasscode,
    },
  });
}

export function trackPhoneNumberVerificationSuccess(data: GetPhoneOTPData) {
  trackEvent(UserSignUpActivityEvents.SIGN_UP_PHONE_VERIFICATION_SUCCESS, {
    phone_number: data.phoneNumber,
    phone_prefix: data.callingCode,
  });
}

// Complete Profile Events
export function trackSignupCompleteProfileSuccessfully(
  data:
    | CompleteUserProfileData
    | (DeprecatedCompleteUserProfileData & {
        signupV3?: boolean;
      }),
) {
  trackEvent(
    UserSignUpActivityEvents.SIGN_UP_COMPLETE_PROFILE_SUCCESSFULLY,
    data,
  );
}

// Google Tag Manager Events
export function trackGoogleTagSignUpEvent() {
  const payload = {
    "Taager ID": user.id,
    Platform: getUserPlatform(),
    "Phone Number": user.phoneNumber,
    "User Location": getUserCountryLocation(),
    // Channel: this._localStorageService.getStorage(CUSTOMER_TOUCH_POINT),
  };

  gTagEvent(UserSignUpActivityEvents.SIGN_UP_GOOGLE_TAG, payload);
}

export function trackSignUpByGoogleSuccess(data?: { signupV3?: boolean }) {
  trackEvent(UserSignUpActivityEvents.SIGN_UP_BY_GOOGLE_SUCCESS, data);
}

export function trackSignUpByEmailSuccess(data?: { signupV3?: boolean }) {
  trackEvent(UserSignUpActivityEvents.SIGN_UP_BY_EMAIL_SUCCESS, data);
}
