import { resolveFirebaseRemoteConfigRepository } from "@di/app";
import { GenericObject } from "@taager/reinforcements";
import { type FeatureProviderContract } from "../../domain";

// why would we set features as an object not an array of string?
// because it will perform better for fast lookups

class FireBaseFeatureProvider implements FeatureProviderContract {
  protected featuresList: GenericObject = {};

  protected isLoading = false;

  public async init(): Promise<void> {
    await this.loadFeatures();
  }

  public get isLoaded(): boolean {
    return Object.keys(this.featuresList).length > 0 && !this.isLoading;
  }

  /**
   * Load features from API
   */
  protected async loadFeatures(): Promise<void> {
    this.isLoading = true;
    this.featuresList = await resolveFirebaseRemoteConfigRepository().list();

    this.isLoading = false;
  }

  public async isEnabled(featureName: string): Promise<boolean> {
    if (!this.isLoading) {
      return this.featuresList[featureName] || false;
    }

    return this.waitUntilLoaded(() => this.featuresList[featureName] || false);
  }

  public isEnabledSync<T>(featureName: string): T | undefined {
    return this.featuresList[featureName];
  }

  public getValue<T>(featureName: string): Promise<T> {
    return this.waitUntilLoaded(() => this.featuresList[featureName] as T);
  }

  /**
   * Wait until the features list is loaded
   */
  protected async waitUntilLoaded<T>(callback: () => T): Promise<T> {
    if (this.isLoading) {
      return new Promise(resolve => {
        setTimeout(() => {
          resolve(this.waitUntilLoaded(callback));
        }, 50);
      });
    }

    return callback();
  }
}

export const firebaseFeatureProvider = new FireBaseFeatureProvider();
