import { country } from "@features/country/data";
import {
  completedUrlsPages,
  getCountryCodeFromUrl,
} from "@features/shared/presentation";
import { navigateTo, NAVIGATING } from "@taager/react-router";

function hardNavigation(url: string) {
  const fullUrl = new URL(window.location.href);

  fullUrl.pathname = `/${country.shortCode || getCountryCodeFromUrl()}${url}`;

  window.location.href = fullUrl.toString();

  // this return is used to stop rendering the page component as this function may be middleware
  return NAVIGATING;
}

export function navigate(url: string) {
  if (completedUrlsPages.includes(url)) {
    return navigateTo(url);
  }

  return hardNavigation(url);
}
