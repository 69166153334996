import { parseError } from "@features/shared/data";
import { createAtom } from "@taager/atom";

export type ToastAtom = {
  type: "success" | "error" | "info";
  message: string;
  title?: string;
};

export const toastAtom = createAtom<ToastAtom>({
  key: "toast",
  default: {},
});

/**
 * Show a success toast message
 */
export function toastSuccess(message: string, title?: string): void {
  toastAtom.update({ type: "success", message, title });
}

/**
 * Show an error toast message
 */
export function toastError(message: string, title?: string): void {
  toastAtom.update({ type: "error", message, title });
}

/**
 * Show an info toast message
 */
export function toastInfo(message: string, title?: string): void {
  toastAtom.update({ type: "info", message, title });
}

/**
 * Parse and show error message in toast
 */
export function catchError(error: any): void {
  toastError(parseError(error));
}
